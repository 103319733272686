import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
const MainTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead td {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }
`;

export default function ProjectInstruction() {
  return (
    <>
      <p>Alert emails will not be sent when creating projects from here.</p>
      <MainTable>
        <thead>
          <tr>
            <td>Column</td>
            <td>Description</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ID</td>
            <td>
              The internal code of the project to import, it will be used as a reference for other data types if
              necessary.
            </td>
          </tr>
          <tr>
            <td>Client Name</td>
            <td>
              The Name of the client associated with this project. This field must match the Name of a Client exactly,
              including spaces, punctuation and capitalization, otherwise the project may skipped during import.
            </td>
          </tr>
          <tr>
            <td>Project&nbsp;Name</td>
            <td>The Name of project.</td>
          </tr>
          <tr>
            <td>Billing Type</td>
            <td>
              Set the value to exactly 'tm', 'fixed', or 'non_billable'. This will represent Time and Materials, Fixed
              Fee, and Non-billable. Defaults to Time and Materials if nothing is provided.
            </td>
          </tr>

          <tr>
            <td>Use Roles</td>
            <td>Set this value to 'Yes' or 'No'.</td>
          </tr>
          <tr>
            <td>Use Budget</td>
            <td>Set this value to 'Yes' or 'No'.</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>Provide a description of the project.</td>
          </tr>
          <tr>
            <td>Project Type</td>
            <td>
              Provide full proper name of the project type. This must match to a project type name already in this
              workspace.
            </td>
          </tr>
          <tr>
            <td>Start</td>
            <td>Starting date for the project formatted 'MM/DD/YYYY'.</td>
          </tr>
          <tr>
            <td>End</td>
            <td>Ending date for the project formatted 'MM/DD/YYYY'.</td>
          </tr>
          <tr>
            <td>PO Number</td>
            <td>Purchase order number associated with a project.</td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>Currency associated with the project.</td>
          </tr>
          <tr>
            <td>Admin Email</td>
            <td>
              Email address of the member who is project admin, must match exactly with member profile including
              capitalization, puncuation, and white space.
            </td>
          </tr>
          <tr>
            <td>Summary Budget</td>
            <td>A numeric value to set as the summary budget hours for the whole project.</td>
          </tr>
          <tr>
            <td>Summary Budget Hours</td>
            <td>A dollar value to set as the summary budget for the whole project.</td>
          </tr>
          <tr>
            <td>Strict Roles</td>
            <td>
              Set this value to 'Yes' or 'No' to configure the option to force all time entries to be against assigned
              roles only.
            </td>
          </tr>
          <tr>
            <td>Tags</td>
            <td>
              Semi-colon seperate list of tags to assocate with this project. (i.e. 'North America', 'Tier 1', etc.){' '}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              Provide a status set to one of the following: 'not_started', 'paused', 'in_progress', or 'completed'
            </td>
          </tr>
          <tr>
            <td>Archived</td>
            <td>
              Set this value to 'Yes' or 'No' to set the project's archival status, which will prevent new time entries
              or expenses from being tracked on the project.
            </td>
          </tr>
          <tr>
            <td>Locked</td>
            <td>
              This will prevent new time entries or expenses from being tracked on the project even if the project is
              still in progress and active.
            </td>
          </tr>

          <tr>
            <td>Approval Method</td>
            <td>
              Provide a value of 'auto' to set this project to be automatically approved, otherwise the approval setting
              will remain manual. This will apply to both time and expenses.
            </td>
          </tr>
        </tbody>
      </MainTable>
    </>
  );
}
