import { BackLink, Button, Icon, Level, Page, Percent, RouteLink, Tooltip } from '~/components';
import { useWorkspace } from '~/contexts';
import path from 'path-browserify';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import OpportunityStagesPopover from './OpportunityStagesPopover';
import OpportunityToProjectForm from './OpportunityToProjectForm';
import getGradientColor from './components/gradient';
import { Tag, Tags } from '../components/HeaderStyledComponents';
import SalesforceIndicator from '../components/SalesforceIndicator';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Eyebrow = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const OpportunityName = styled.h1`
  && {
    font-size: 1.5rem;
    font-weight: ${weights.light};
    margin-top: 0.25rem;
  }
`;

const PipelineStatusTag = styled(Tag)`
  .icon {
    font-size: 0.625rem;
    margin-right: 0.7rem;
    color: ${({ status, probability }) => {
      if (status === 'open') {
        // Get interpolated color based on probability
        return getGradientColor(probability);
      } else {
        // Use predefined colors for other statuses
        return (
          {
            won: colors.success,
            lost: colors.danger,
          }[status] || colors.warning0
        ); // Fallback to 'warning0' if status is not recognized
      }
    }};
  }
`;

const EditLink = styled(Link)`
  margin-left: auto;
  align-self: center;
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;

  &:hover {
    background-color: ${colors.grey10};
  }
`;

const OpenProjectButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

function OpportunityHeader({ opportunity, onStageChange }) {
  const location = useLocation();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { name, opportunityStage, company, permissions } = opportunity;
  const [opportunityToProject, setOpportunityToProject] = useState(null);

  return (
    <>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/opportunities`} />
        <InfoContainer>
          <Eyebrow>Opportunity Dashboard</Eyebrow>
          {opportunity && <OpportunityName>{name}</OpportunityName>}

          <Tags>
            <Tag>
              {company && (
                <RouteLink to={`/app/${workspace.key}/pipeline/companies/${company.id}/overview`}>
                  {company.name}
                </RouteLink>
              )}
            </Tag>
            {opportunity.salesforceOpportunity?.salesforceId && (
              <Tag>
                Synced
                <SalesforceIndicator />
              </Tag>
            )}
            <PipelineStatusTag status={opportunityStage?.statusId} probability={opportunityStage?.probability}>
              {opportunityStage && (
                <OpportunityStagesPopover opportunity={opportunity} onStageChange={onStageChange}>
                  <Icon icon="circle" />
                  <span>
                    {opportunityStage.name}&nbsp;-&nbsp;
                    <Percent value={opportunityStage.probability / 100} minimumFractionDigits={0} />
                  </span>
                </OpportunityStagesPopover>
              )}
            </PipelineStatusTag>
            {opportunityStage.description && (
              <Tag>
                <i>{opportunityStage.description}</i>
              </Tag>
            )}
          </Tags>
        </InfoContainer>

        <Level right>
          {permissions.manage && !opportunity.project && (
            <Level.Item>
              <Tooltip message="Create Project">
                <OpenProjectButton onClick={() => setOpportunityToProject(opportunity)}>
                  <Icon color={colors.primary} icon="plus" />
                </OpenProjectButton>
              </Tooltip>
            </Level.Item>
          )}

          {permissions.manage && (
            <Level.Item>
              <Tooltip message="Edit Opportunity">
                <EditLink to={`${resolveEditPath('edit', location)}${location.search}`}>
                  <Icon icon="pencil-alt" />
                </EditLink>
              </Tooltip>
            </Level.Item>
          )}
        </Level>
      </Page.Header>

      {permissions.manage && opportunityToProject && (
        <OpportunityToProjectForm
          opportunity={opportunityToProject}
          onClose={() => setOpportunityToProject(false)}
          onSaved={(project) =>
            history.push(`/app/${workspace.key}/projects/${project.client.key}/${project.key}/overview/edit`)
          }
        />
      )}
    </>
  );
}

export default OpportunityHeader;
